/* src/styles/About.css */

/* Общий стиль для секции About */
.about-section {
    font-family: Arial, sans-serif;
    color: #333;
}

/* Стиль для заголовка с фоном */
.about-header {
    position: relative;
    height: 600px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c4d050; /* Цвет текста */
}

.about-header-content {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}

.about-header-content h1 {
    font-size: 3.5em;
    line-height: 1.2;
    margin: 0;
}

/* Секция с описанием услуг */
.about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 5%;
    gap: 40px;
}

.about-content-left {
    flex: 1;
}

.about-image {
    width: 100%;
    border-radius: 10px;
}

.about-content-right {
    flex: 1;
}

.about-content-right h4 {
    font-size: 2em;
    color: #00706b;
    margin-bottom: 20px;
}

.about-content-right p {
    line-height: 1.6;
}

/* Секция с перечислением услуг */
.about-services {
    background-color: #f4f4f4;
    padding: 50px 10%;
    text-align: center;
}

.about-services h4 {
    font-size: 2.5em;
    color: #00706b;
    margin-bottom: 30px;
}

.about-services ul {
    list-style: disc;
    list-style-position: inside;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    line-height: 1.8;
}

/* Секция с дополнительной информацией */
.about-additional-info {
    padding: 50px 10%;
    text-align: center;
}

.about-additional-info h4 {
    font-size: 2.5em;
    color: #00706b;
    margin-bottom: 30px;
}

.about-additional-info ul {
    list-style: disc;
    list-style-position: inside;
    text-align: left;
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    line-height: 1.8;
}
