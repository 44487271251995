/* src/styles/Footer.css */
.footer {
    background-color: #2c2461; /* Темно-синий цвет фона */
    padding: 40px 0;
    color: #fff;
}

.widgets_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
}

.column {
    flex: 1;
    text-align: center;
}

.widget_text .themecolor {
    color: #c4d050; /* Зеленоватый цвет текста для заголовка */
    font-weight: bold;
    margin-bottom: 15px;
}

.contact {
    font-size: 16px;
    line-height: 1.6;
}

.contact a {
    color: #fff;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}
