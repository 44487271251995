.contact-header .slider-background {
    height: calc(100vh - 104px);
    min-height: 495px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.contact-header .slider-content h1 {
    color: #A5C559; /* Зелёный цвет, как на изображении */
    /*font-size: 48px;*/
    padding: 150px 0 0 50px;
}

.address-section {
    background-color: #f4f4f4;
    padding: 75px 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-content {
    display: flex;
    align-items: center;
    width: 80%;
    gap: 50px;
}

.address-info {
    color: #555;
}

.address-image{
    width:75%
}

.form-section {
    text-align: center;
    padding: 75px 0;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
}

.contact-form .form-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.contact-form input {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
}

.submit-btn {
    background-color: #A5C559;
    color: #fff;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
}

.map-section {
    padding: 50px 0;
    text-align: center;
}
