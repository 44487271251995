/* src/styles/Tickets.css */

/* Общий контент */
#Content {
    font-family: Arial, sans-serif;
    padding: 20px;
}

/* Заголовок с фоном */
.tickets-header {
    background-size: cover;
    background-position: center;
    height: calc(100vh - 104px);
    min-height: 444px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    text-align: center;
}

/* Полупрозрачный слой поверх фонового изображения */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1); /* Полупрозрачный черный слой */
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-content {
    z-index: 1;
    color: #ffffff;
}

.slider-content h1 {
    font-size: 2.5em;
    line-height: 1.2;
    max-width: 800px;
}

/* Список услуг */
.feature-list {
    margin-top: 40px;
}

.feature-list ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.feature-list ul li {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 1.1em;
    background-color: #fff;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

}

.feature-list ul li:hover {
    background-color: #c4d050;
    color: #fff;
}

.feature-list ul li:hover svg {
    color: white;
}

.FontAwesome {
    color: #c4d050;
}

.feature-list ul li i {
    color: #c4d050;
    font-size: 1.5em;
    margin-right: 10px;
}

.feature-list ul li:hover i {
    color: #fff;
}

/* Блок "By offering our cooperation" */
.cooperation-section {
    margin-top: 50px;
    text-align: center;
}

.cooperation-section h2 {
    font-size: 2em;
    color: #00706b;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cooperation-section h2:before,
.cooperation-section h2:after {
    content: '►';
    margin: 0 10px;
    color: #c4d050;
}

.cooperation-icons {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.icon-box {
    width: 250px;
    text-align: center;
}

.icon-box img {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 15px;
}

.icon-box h4 {
    font-size: 1.3em;
    color: #00706b;
}

.icon-box p {
    color: #666;
}
