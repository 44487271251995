/* src/styles/Home.css */
.slider {
    position: relative;
    width: 100%;
    height: calc(100vh - 104px);
    min-height: 415px;
    overflow: hidden;
}

.slider-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 5px;
}

.dot1, .dot2, .bounce1, .bounce2, .bounce3 {
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.slider-background {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center top;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-content {
    text-align: left;
    position: absolute;
    top: 10%;
    left: 10%;
    color: #c4d050;
}

.slider-content h1 {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 110px;
    font-weight: 500;
    line-height: 100px;
    letter-spacing: 1px;
    margin: 0;
}
