.logo img {
    height: 30px;
}

.navbar-nav{
    font-family: 'Barlow Condensed';
    font-weight: bold;
    font-size: 20px;
}

.navbar-nav a.nav-link.active{
    color: #c4d050;
    text-decoration: underline;
    font-weight: bold;
}